@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

input::-moz-placeholder {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.75rem;
  color: #727272;
}

input:-ms-input-placeholder {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.75rem;
  color: #727272;
}

input::placeholder,
input[type='datetime-local' i] {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.75rem;
  color: #727272;
}

input::-moz-placeholder {
  line-height: 1rem;
}

input:-ms-input-placeholder {
  line-height: 1rem;
}

input::placeholder {
  line-height: 1rem;
}

a {
  text-decoration: none;
  color: #f05323;
  cursor: pointer;
  transition: 0.225s all;
}

a:hover {
  color: #d63a0b;
}

.ProseMirror-focused {
  outline-offset: 0;
  outline-width: 3px;
}

.ProseMirror h2 {
  margin: 0 0 0.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 130%;
}

.ProseMirror h3 {
  margin: 0 0 0.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 130%;
}

.ProseMirror p {
  margin: 0 0 1rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.ProseMirror p:last-of-type {
  margin: 0;
}

.ProseMirror ol,
.ProseMirror ul {
  margin: 0;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

